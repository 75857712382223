<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed flex flex-col items-end px-2 py-4 sm:items-start sm:p-5 top-0 right-1"
  >
    <div
      v-for="item in notifications"
      :key="item.id"
      class="flex w-full items-center sm:items-end my-1"
    >
      <div
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-secondary-darker shadow-lg ring-1 ring-common-black ring-opacity-5"
      >
        <div class="p-3">
          <div class="flex items-start">
            <div class="flex-shrink-0 pt-1">
              <!-- Top left icon -->
              <BIconCheckCircle
                v-if="item.type === 'success'"
                class="text-special-green-text h-5 w-5"
              />
              <BIconInfoCircle
                v-else-if="item.type === 'warn'"
                class="text-special-yellow-text h-5 w-5"
              />
              <BIconExclamationCircle
                v-else-if="item.type === 'error'"
                class="text-special-red-text h-5 w-5"
              />
            </div>
            <div class="mx-3 flex-1 pt-0.5">
              <p
                class="text-sm font-bold"
                :class="[
                  { 'text-special-green-text': item.type === 'success' },
                  { 'text-special-yellow-text': item.type === 'warn' },
                  { 'text-special-red-text': item.type === 'error' },
                ]"
              >
                {{ item.title }}
              </p>

              <p
                class="text-sm"
                :class="[
                  { 'text-special-green-text': item.type === 'success' },
                  { 'text-special-yellow-text': item.type === 'warn' },
                  { 'text-special-red-text': item.type === 'error' },
                ]"
              >
                {{ item.text }}
              </p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button
                type="button"
                class="inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                :class="[
                  {
                    'text-special-green-text hover:bg-special-green-bg focus:ring-offset-a-special-green-text focus:ring-special-green-text':
                      item.type === 'success',
                  },
                  {
                    'text-special-yellow-text hover:bg-special-yellow-bg focus:ring-offset-special-yellow-text focus:ring-special-yellow-text':
                      item.type === 'warn',
                  },
                  {
                    'text-special-red-text hover:bg-special-red-bg focus:ring-offset-special-red-text focus:ring-special-red-text':
                      item.type === 'error',
                  },
                ]"
                @click="removeNotification(item.id)"
              >
                <span class="sr-only">Verwerfen</span>
                <BIconXLg class="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BIconCheckCircle,
  BIconExclamationCircle,
  BIconInfoCircle,
  BIconXLg
} from 'bootstrap-icons-vue'
import { useNotifications } from '~/composables/states'

const { notifications, removeNotification } = useNotifications()
</script>

<style scoped></style>
